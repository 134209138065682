<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>Wallet Balance Daily</h4> 
              </CCol>
              <!--CCol md="5">                   
                    <CRow>
                      <CCol md="3">
                          By User Id
                      </CCol>
                      <CCol md="7">
                        <vue-typeahead-bootstrap
                          label = "sdasda"
                          v-model="phoneNum"              
                          :data="optNumber">
                          <template #append>
                            <CButton color="success" v-on:click="actionChange()">Search</CButton>
                          </template>
                        </vue-typeahead-bootstrap>
                        </CCol>
                     </CRow>
              </CCol-->
              <CCol md="5">
                   <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />                   
              </CCol>
              <CCol md="2">                 
                    <!-- <vue-excel-xlsx 
                        style="color:green"                       
                        :data="data"
                        :columns="columns"                      
                        :filename="'Wallet-Balance'"
                        :sheetname="'Wallet-Balance'"
                        >
                        Download
                    </vue-excel-xlsx>                     -->
                    <CButton v-on:click="showFormPasswd()" color="success">Download</CButton>            
              </CCol>
          </CRow>
        </CCardHeader><br/>
        <CCardBody> 

              <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
              </CRow>  

              <div class="text-center">
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
              </div>   

                     <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">  
                        <b-card border-variant="dark" header="Protected Excel Password" align="center">
                        <b-card-text>
                            <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                        </b-card-text>                                                                      
                        <b-button-group>
                           <b-button v-on:click="actionCancel()">Cancel</b-button>
                           <b-button variant="success" v-on:click="generateWorkbook()">Download</b-button>
                        </b-button-group>                                   
                        </b-card>
                     </b-card-group>

        <div class="table-responsive table">
          <b-table striped hover  :items="items" :fields="fields" v-model="list" :current-page="currentPage" :per-page="perPage" :filter="filter">              

          </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ReportService from '@/api/ReportService.js';
import moment from 'moment';
// import Excel from 'exceljs';
// import FileSaver from 'file-saver';
// import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate.min.js';

 export default {
    name: "walletbalance",
    components: {},     
    data() {
      return {
        spinner:false,
        list:[],
        columns : [],
        columns2 : [],
        data : [],
        items:[],             
        fields: [], 
        currency:[], 
        optNumber:[],       
        phoneNum:'',     
        currentPage: 1,
        perPage: 10,
        filter: null,
        formPasswd:false,
        filename:"wallet-balance-report.xlsx",
        password:"",
        seen:false,
        msg :'',
        color:''
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        formatNum(value){ console.log(value);
           if(!value) return 0  
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
        }
    },

    created () { 
       this.spinner = true; 
       this.setDefaultPasswd();
       this.getWalletBalanceReport();        
       this.setColumns(); 
    },
    methods: { 

      showFormPasswd(){
         this.formPasswd = true;
      },

      actionCancel(){
          this.formPasswd = false;
      },
       
      setDefaultPasswd(){
        var today = moment().format('DDMMYYYYHHmmss');
        var user  = this.$store.getters.getUser; 
        this.password = user+today;
      },
      
      generateWorkbook(){

         this.formPasswd = false; this.spinner=true;
         var cols  = this.columns; var rows  = this.data;        
         var heads = []; var temp =[]; var data=[];
         let params = {}; var colkey =[];         

          for (var key in cols) {
             var col = cols[key]; heads.push(col.label);
             colkey.push(col.field);
          }           
          data.push(heads); var jum = heads.length; 

          for (var key2 in rows) { var row = rows[key2]; 
              var init = Array(jum).fill(null);
              for(var key4 in colkey){
                for (var key3 in row) { 
                  var val  = row[key3];                             
                  var ckey = colkey[key4]; 
                  if(key3===ckey){ init[key4] = val; 
                  }              
                }
              }
              data.push(init); 
          } 
         
         //  var str = JSON.stringify(params);
         //  var first = str.replace(/['[']/g,'{');
         //  var scnd  = first.replace(/(\])/g,'}');

         params['password'] = this.password;
         params['data'] = data;   //console.log(params); 
         this.getReportExcel(params);
      },

      getReportExcel: function(params) {
        ReportService.getReportExcel(params).then(resp => {             
         try {  
            if(resp.status==200){
               var filename = this.filename;
               const link = document.createElement('a');    
               link.href = window.URL.createObjectURL(
                   new Blob([resp.data])
               );   
               link.setAttribute('download', filename);
               document.body.appendChild(link);
               link.click(); this.spinner = false; 
            } else {
              this.msg   = 'Sorry, Generated Excel Failed!'; 
              this.color = 'warning'; this.seen  = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 5000);
            }
           }
         catch(err) {            
            this.msg   = 'Sorry, Generated Excel Failed!'; 
            this.color = 'warning'; this.seen  = true;
            this.spinner = false; 
            setTimeout( () =>  this.seen=false , 5000);
         } 

        }, error => { 
           this.spinner = false;            
        });
      }, 

      createWorksheet(){         
          
          // let workbook = new Excel.Workbook();
          // let worksheet = workbook.addWorksheet("Tutorials");         

          // worksheet.columns = [
          //   { header: "Id", key: "id", width: 5 },
          //   { header: "Title", key: "title", width: 25 },
          //   { header: "Description", key: "description", width: 25 },
          //   { header: "Published", key: "published", width: 10 },
          // ];  
          
          // workbook.xlsx.writeBuffer().then((data) => {
          //   const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
          //   FileSaver.saveAs(blob, 'test.xlsx');
          // });

          //  XlsxPopulate.fromBlankAsync().then(workbook => {
          //       // Modify the workbook.
          //       workbook.sheet("Sheet1").cell("A1").value("This is neat!");
          //       //return workbook.toFileAsync("./test.xlsx", { password: "123" });
          //       //workbook.toFileAsync("/home/anggun/Downloads/test.xlsx", { password: "S3cret!" });

          //       workbook.outputAsync("base64")
          //       .then(function (base64) {
          //           location.href = "data:" + XlsxPopulate.MIME_TYPE + ";base64," + base64;
          //       });    
          //  }); 
      },

      getFilterData(){          
         this.data  = this.list;
      },

      setColumns(fields){        
        let row = {}; var title = {}; var cols = [];  
        for (var key in fields) {    
           var label = fields[key].label; 
           row['label'] = label;
           row['field'] = fields[key].key;
           var temp = JSON.stringify(row); cols.push(temp);
        }   

        for (var key2 in cols) {               
           var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
        }

        //console.log(this.columns);

      },

      formatNum(value){
         return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") 
      }, 
      
      

      getWalletBalanceReport: function() {
        ReportService.getWalletBalanceReport('all').then(resp => {    

           let item = {}; var curr = [];  var rows = []; var newrows = [];

           for (var key in resp) {              
              item['userId'] = resp[key].userIdentify;
              item['mobileNumber'] = resp[key].mobileNumber;
              item['userName'] = !resp[key].userName ? "None" : resp[key].userName;

              var wallets = resp[key].wallets;
              for (var key2 in wallets) {                
                 item['walletId'] = wallets[key2].walletId;                 
                 var balances = wallets[key2].balance;
                 for (var key3 in balances) {
                    if(!curr.includes(key3)){ curr.push(key3); }
                    item[key3] = balances[key3];
                 } 
                 var temp = JSON.stringify(item);
                 rows.push(temp); 
              } 
              item = {}; 
           }           

           var notcurr = ['userId','mobileNumber','userName','walletId']; 
           var currrow = [];
           for (var key5 in rows) { 
              var row = JSON.parse(rows[key5]); newrows.push(row); 
              for (var key6 in row) {  
                if(!notcurr.includes(key6)){ currrow.push(key6); }         
              }
                 
              for (var key7 in curr) { 
                var val = curr[key7];  //console.log(val);
                if(!currrow.includes(val)){ newrows[key5][val]=0; }
              }
              currrow=[];                
           }

           this.data  = newrows;

           this.items = newrows; this.currency = curr;
           var fields = []; var ls = {}; var label ='';
           var cols   = notcurr;
           var keys   = cols.concat(curr);

           for (var key4 in keys) { 

              if(keys[key4]=='userId'){ label = "User ID"; }
              else if(keys[key4]=='walletId') { label = "Wallet ID"; }
              else if(keys[key4]=='mobileNumber') { label = "Mobile Number"; }
              else if(keys[key4]=='userName') { label = "User Name"; }
              else { label = keys[key4]; }

              ls['label'] = label;
              ls['key']   = keys[key4];
              ls['sortable'] = true;
              fields.push(ls); ls = {}; label ='';
           }
           this.fields = fields;  this.spinner = false; 
           this.setColumns(fields);

        }, error => {
           this.spinner = false;
        });
      }
    }
 }; 

</script>
<style>
    .table{ overflow-y:scroll; }
</style>